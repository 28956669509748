import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { UserMFASettingsService } from './user-mfa-settings.service';
import { UserMFASettingsBaseData } from '../../generated-models/api/usersettings/mfa';

export const userMFASettingsResolver: ResolveFn<
	HttpResponse<UserMFASettingsBaseData>
> = () => {
	return inject(UserMFASettingsService).getBaseData();
};
