@if (showFormField) {
	<div
		class="boolean-text-select-input-form-field-container"
		[ngClass]="{ 'full-width': fullWidth, short: short }">
		<button mat-button (click)="toggleState()">
			<span
				[ngClass]="{
					'neutral-selection': ($state | async) === null,
					'true-selection': ($state | async) && ($state | async) !== null,
					'false-selection': !($state | async) && ($state | async) !== null
				}"
				>{{ label }}</span
			>
		</button>

		@if (formCtrl && formCtrl.hasError('required')) {
			<mat-error>A {{ errorLabelToLower }} selection is required.</mat-error>
		}

		<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
			@if (formCtrl && formCtrl.hasError(customError.type)) {
				<mat-error>{{ customError.message }}</mat-error>
			}
		</ng-template>
	</div>
}
