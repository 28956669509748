import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	EMPTY,
	Observable,
	Subject,
	Subscription,
	catchError,
	finalize,
	map,
	switchMap,
	tap,
	throwError,
	timer
} from 'rxjs';
import { BaseService, HandleResponseConfig, handleResponse } from '../shared/services';
import { NotificationService } from '../layout';
import { Router } from '@angular/router';
import {
	ChangePasswordRequest,
	ConfirmEmailChangeRequest,
	ConfirmEmailRequest,
	ForgotPasswordRequest,
	LoginRequest,
	MFAOption,
	MFARequest,
	ResetPasswordRequest,
	UserInfo,
	ValidateTenantCodeRequest
} from '../generated-models/api/auth';
import { IdLabel } from '../generated-models/api/shared';
import { AuthBaseData, ChangeUserFacilityRequest } from '../generated-models/api/auth';
import { Tenant } from '../generated-models/api/tenant';

// TODO: Put this somewhere else.
export enum MFATypeEnum {
	None = 0,
	PhoneNumber = 1,
	TOTP = 2
}

@Injectable({
	providedIn: 'root'
})
export class AuthService extends BaseService<AuthBaseData> {
	private _userInfo: BehaviorSubject<UserInfo | null> = new BehaviorSubject<UserInfo | null>(null);

	public $userInfo: Observable<UserInfo | null> = this._userInfo.asObservable();

	private _sessionExpiringSoon: Subject<number> = new Subject();

	/** Emits the minutes left when the session is going to expire soon. */
	public $sessionExpiringSoon: Observable<number> = this._sessionExpiringSoon.asObservable();

	private _showingSessionExpirationWarning: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public $showingSessionExpirationWarning: Observable<boolean> =
		this._showingSessionExpirationWarning.asObservable();

	private _sessionTimeLeftMS: BehaviorSubject<number> = new BehaviorSubject(0);

	public $sessionTimeLeftMS: Observable<number> = this._sessionTimeLeftMS.asObservable();

	private _logoutWarningTimerSubscription: Subscription | null = null;

	private _MFARequired: BehaviorSubject<LoginRequest | null> = new BehaviorSubject<LoginRequest | null>(null);

	public $MFARequired: Observable<LoginRequest | null> = this._MFARequired.asObservable();

	private _mfaOptions: BehaviorSubject<IdLabel[]> = new BehaviorSubject<IdLabel[]>([]);

	public $mfaOptions: Observable<IdLabel[]> = this._mfaOptions.asObservable();

	get mfaOptions(): IdLabel[] {
		return this._mfaOptions.getValue();
	}

	private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _tenant: BehaviorSubject<Tenant | null> = new BehaviorSubject<Tenant | null>(null);

	public $tenant: Observable<Tenant | null> = this._tenant.asObservable();

	get tenantCode(): string | null {
		const tenant = this._tenant.getValue();

		if (tenant && tenant?.code) {
			return tenant?.code;
		}

		return null;
	}

	constructor(
		_httpClient: HttpClient,
		_notificationService: NotificationService,
		private _router: Router
	) {
		super('auth', _httpClient, _notificationService);
	}

	/** Determine if a user is currently logged in. */
	get isLoggedIn(): boolean {
		return this._isLoggedIn.getValue();
	}

	/** The currently logged in user's info. */
	get userInfo(): UserInfo | null {
		return this._userInfo.getValue();
	}

	public validateAndSetTenantCode(tenantCode: string): Observable<HttpResponse<void>> {
		this.setIsProcessing();

		const request: ValidateTenantCodeRequest = {
			tenantCode: tenantCode
		};

		return this._httpClient
			.post<Tenant>(`${this.apiEndpoint}/validate-tenant-code`, request, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: '',
					hideErrorMessage: true,
					customErrorFilter: errorResponse => {
						if (errorResponse.status === 403) {
							this._router.navigate([`tenant-access-denied`]);
							return null;
						}

						return null;
					}
				} as HandleResponseConfig),
				tap(response => {
					if (response.ok) {
						this._tenant.next(response.body);
					}
				}),
				map(m => m as HttpResponse<void>),
				finalize(() => this.removeIsProcessing())
			);
	}

	public login(loginRequest: LoginRequest, onOkCallback: (response: HttpResponse<void>) => void) {
		this.setIsProcessing();

		return this._httpClient
			.post<void>(`${this.apiEndpoint}/login`, loginRequest, {
				observe: 'response'
			})
			.pipe(
				catchError((err: HttpErrorResponse) => {
					if (err.status === 401 && err.error.detail === 'MFAPhoneNumberSetupRequired') {
						this.performMFAPhoneNumberSetupTasks();
						return EMPTY;
					}

					if (err.status === 401 && err.error.detail === 'MFATOTPSetupRequired') {
						this.performMFATOTPSetupTasks();
						return EMPTY;
					}

					if (err.status === 401 && err.error.detail === 'RequiresTwoFactor') {
						const allowedMFATypes = err.error.allowedMFATypes;
						const mfaOptions: IdLabel[] = [];

						if ((allowedMFATypes & MFATypeEnum.PhoneNumber) === MFATypeEnum.PhoneNumber) {
							mfaOptions.push({
								id: MFAOption.SMS,
								label: 'SMS'
							} as IdLabel);
							mfaOptions.push({
								id: MFAOption.PhoneCall,
								label: 'Phone Call'
							} as IdLabel);
						}

						if ((allowedMFATypes & MFATypeEnum.TOTP) === MFATypeEnum.TOTP) {
							mfaOptions.push({
								id: MFAOption.TOTP,
								label: 'Authenticator'
							} as IdLabel);
						}

						this._mfaOptions.next(mfaOptions);

						this._MFARequired.next(loginRequest);
						return EMPTY;
					}

					return throwError(() => err);
				}),
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed to login.',
					customErrorFilter: errorResponse => {
						if (errorResponse.status === 401) {
							if (errorResponse.error.detail === 'LockedOut') {
								console.log(this._userInfo.getValue());
								return `You have been locked out, try again after 10 minutes.`;
							}

							if (errorResponse.error.detail === 'Failed') {
								return 'The username or password entered is invalid.';
							}

							if (errorResponse.error.detail === 'MFACodeInvalid') {
								return 'The MFA code entered is invalid.';
							}
						}

						return null;
					}
				} as HandleResponseConfig),
				tap(response => {
					if (response.ok) {
						this._isLoggedIn.next(true);
					}
				}),
				switchMap(() => this.loadUserInfoIfValidSession()),
				tap(response => {
					// This is setup to early in the login process need to make sure userinfo is loaded.

					if (response.ok) {
						//this.restartLogoutWarningTimer();
						onOkCallback(response as HttpResponse<void>);
					}
				}),
				finalize(() => this.removeIsProcessing())
			);
	}

	public logout(): Observable<HttpResponse<void>> {
		return this._httpClient
			.get<void>(`${this.apiEndpoint}/logout`, {
				observe: 'response'
			})
			.pipe(
				// catchError((err: HttpErrorResponse) => {
				// 	console.log('catch');
				// 	if (err.status === 401) {
				// 		// Likely the cookie is already expired if this fails, so need to take some actions client side.
				// 		console.log('401');
				// 	}
				// 	return err;
				// }),

				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed to logout.',
					hideErrorMessage: true,
					customErrorFilter: errorResponse => {
						if (errorResponse.status === 401) {
							// Likely the cookie is already expired if this fails, so need to take some actions client side.
						}

						return null;
					}
				} as HandleResponseConfig),
				map(m => m as HttpResponse<void>),
				finalize(() => {
					this.performClientSideLogoutTasks();
					this.removeIsProcessing();
				})
			);
	}

	public performClientSideLogoutTasks(): void {
		this._userInfo.next(null);
		this._sessionTimeLeftMS.next(0);
		this._router.navigate([`/${this.tenantCode}/auth/login`]);
	}

	public performPasswordExpiredTasks(): void {
		this._router.navigate([`/${this.tenantCode}/auth/password-expired`]);
	}

	public performMFAPhoneNumberSetupTasks(): void {
		this._router.navigate([`/${this.tenantCode}/auth/mfa-phone-number-setup`]);
	}

	public performMFATOTPSetupTasks(): void {
		this._router.navigate([`/${this.tenantCode}/auth/mfa-totp-setup`]);
	}

	public loadUserInfoIfValidSession(): Observable<HttpResponse<UserInfo>> {
		this.setIsProcessing();
		return this._httpClient
			.get<UserInfo>(`${this.apiEndpoint}/user-info`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: '',
					hideErrorMessage: true
				} as HandleResponseConfig),
				tap(response => {
					if (response.ok) {
						this._userInfo.next(response.body);

						if (response.body?.tenant.code !== this.tenantCode) {
							this.performClientSideLogoutTasks();
						}
					}
				}),
				finalize(() => this.removeIsProcessing())
			);
	}

	public refreshSession(): Observable<HttpResponse<void>> {
		return this.loadUserInfoIfValidSession().pipe(
			tap(response => {
				if (response.ok) {
					//this.restartLogoutWarningTimer();
				}
			}),
			map(m => m as HttpResponse<void>)
		);
	}

	public forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Observable<HttpResponse<void>> {
		return this.post<ForgotPasswordRequest, void>(
			'forgot-password',
			'Failed to send the forgot password request.',
			forgotPasswordRequest
		);
	}

	public resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<HttpResponse<void>> {
		return this.post<ResetPasswordRequest, void>(
			'reset-password',
			'The password reset request failed.',
			resetPasswordRequest
		);
	}

	public changePassword(changePasswordRequest: ChangePasswordRequest): Observable<HttpResponse<void>> {
		return this.post<ChangePasswordRequest, void>(
			'change-password',
			'The change password request failed.',
			changePasswordRequest
		);
	}

	public getUserFacilities(): Observable<HttpResponse<IdLabel[]>> {
		return this._httpClient
			.get<IdLabel[]>(`${this.apiEndpoint}/user-facilities`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed to retrieve the user facilities.'
				} as HandleResponseConfig)
			);
	}

	public changeUserFacility(request: ChangeUserFacilityRequest): Observable<HttpResponse<UserInfo>> {
		return this._httpClient
			.post<UserInfo>(`${this.apiEndpoint}/change-facility`, request, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed to change the user facility.'
				} as HandleResponseConfig),
				tap(response => {
					if (response.ok) {
						this._userInfo.next(response.body);
					}
				})
			);
	}

	public requestSMSMFACode(mfaRequest: MFARequest): Observable<HttpResponse<void>> {
		return this.post<MFARequest, void>('sms-mfa', 'Failed to mfa.', mfaRequest);
	}

	public requestCallMFACode(mfaRequest: MFARequest): Observable<HttpResponse<void>> {
		return this.post<MFARequest, void>('call-mfa', 'Failed to mfa.', mfaRequest);
	}

	public clearMFARequired(): void {
		this._MFARequired.next(null);
	}

	public confirmEmail(confirmEmailRequest: ConfirmEmailRequest): Observable<HttpResponse<void>> {
		return this.post<ConfirmEmailRequest, void>(
			'confirm-email',
			'The confirm email request failed.',
			confirmEmailRequest
		);
	}

	public confirmEmailChange(
		confirmEmailChangeRequest: ConfirmEmailChangeRequest
	): Observable<HttpResponse<void>> {
		return this.post<ConfirmEmailChangeRequest, void>(
			'confirm-email-change',
			'The confirm email change request failed.',
			confirmEmailChangeRequest
		);
	}

	public restartLogoutWarningTimer(sessionTimeLeftMS: number): void {
		this.stopLogoutWarningTimer();
		const minutesLeftToShowWarning = this._userInfo.getValue()?.sessionExpirationWarningShowBeforeMinutes ?? 0;
		const msLeftToShowWarning = 1000 * 60 * minutesLeftToShowWarning;
		const timerInterval = sessionTimeLeftMS - msLeftToShowWarning;

		this._logoutWarningTimerSubscription = timer(timerInterval).subscribe(() => {
			this._sessionExpiringSoon.next(minutesLeftToShowWarning);
		});
	}

	public updateSessionTimeLeftFromCookie(): void {
		document.cookie.split(',').forEach(cookie => {
			if (cookie.startsWith('InSytsEMRSessionExpiration=')) {
				const cookieKeyValue = cookie.split('InSytsEMRSessionExpiration=');

				if (cookieKeyValue && cookieKeyValue[1]) {
					const decodedValue = atob(cookieKeyValue[1]);

					const expirationDateTime = new Date(decodedValue);
					const currentTime = Date.now();
					const timeLeftMS = expirationDateTime.valueOf() - currentTime;
					this._sessionTimeLeftMS.next(timeLeftMS);
				}
			}
		});
	}

	private stopLogoutWarningTimer(): void {
		if (this._logoutWarningTimerSubscription) {
			this._logoutWarningTimerSubscription.unsubscribe();
		}
	}
}
