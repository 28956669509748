import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../shared';
import { NotificationService } from '../../layout';
import { UserPasswordSettingsBaseData } from '../../generated-models/api/usersettings/password';

@Injectable({
	providedIn: 'root'
})
export class UserPasswordSettingsService extends BaseService<UserPasswordSettingsBaseData> {
	constructor(
		_httpClient: HttpClient,
		_notificationService: NotificationService
	) {
		super('user-settings/password', _httpClient, _notificationService);
	}
}
