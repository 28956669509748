import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[weight]'
})
export class WeightDirective {
	constructor(private el: ElementRef) {}

	@HostListener('keypress', ['$event'])
	onKeyPress(event: KeyboardEvent) {
		const regex = new RegExp(/^\d*\.?\d{0,3}$/);
		const inputChar = String.fromCharCode(event.charCode);
		const currentValue = this.el.nativeElement.value + inputChar;

		if (!regex.test(currentValue)) {
			event.preventDefault();
		}
	}

	@HostListener('blur', ['$event'])
	onBlur(event: FocusEvent) {
		const value = this.el.nativeElement.value;
		if (value && !/^\d+(\.\d{1,3})?$/.test(value)) {
			this.el.nativeElement.value = '';
		}
	}
}
