import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import { find, findIndex } from 'lodash';
import { finalize, tap } from 'rxjs';
import { AuthService } from '../../auth';

import * as signalR from '@microsoft/signalr';
import { PatientListService } from '../../patient-list/patient-list.service';
import { HttpClient } from '@angular/common/http';
import { AppLoaderService, NotificationService } from '../../layout';
import { BaseService } from './base-service.service';
import {
	ChiefComplaintRow,
	EncounterLocation,
	Patient,
	PatientTracker,
	ReadNotes
} from '../../generated-models/api';

@Injectable({
	providedIn: 'root'
})
export class SiteWideDataService extends BaseService<void> {
	public connection: signalR.HubConnection | null = null;
	public selectedPatientEncounter = signal<PatientTracker | null>(null);
	public selectedPatient = computed<Patient | null>(() => {
		return this.selectedPatientEncounter()?.patient ?? null;
	});
	public selectedEncounterId = computed<string | null>(() => {
		return this.selectedPatientEncounter()?.encounter?.id ?? null;
	});
	public primaryTenantChiefComplaintId = computed(() => {
		return this.selectedPatientEncounter()?.encounter?.chiefComplaints[0]?.sophosId ?? 0;
	});

	private patientTrackerDataIsLoaded = false;
	private patientTrackerDataPromise: Promise<PatientTracker[]> | null = null;
	private patientTrackerData = signal<PatientTracker[]>([]);

	constructor(
		private readonly _patientListService: PatientListService,
		private readonly _appLoaderService: AppLoaderService,
		readonly _authService: AuthService,
		_httpClient: HttpClient,
		_notificationService: NotificationService
	) {
		super(null, _httpClient, _notificationService);
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(`${_authService.apiBaseEndPoint}/messageHub`)
			.withAutomaticReconnect()
			.build();

		this.connection.start();

		this.connection.on('PatientUpdated', (patientRecord: PatientTracker) => {
			const existingIndex = findIndex(this.patientTrackerData(), patientTracker => {
				return patientTracker.patient.id === patientRecord.patient.id;
			});
			this.patientTrackerData.update((old: PatientTracker[]) => {
				const newDashboardList = old;
				if (existingIndex >= 0) {
					newDashboardList[existingIndex] = patientRecord;
				}
				newDashboardList.push(patientRecord);
				return newDashboardList;
			});
		});

		this.connection.on('EncounterRoomUpdated', (encounterId: string, room: EncounterLocation) => {
			// Update loaded items
			this.patientTrackerData.update(old => {
				if (!old) {
					return old;
				}
				const updatedEncounter = find(old, (x: PatientTracker) => {
					return x.encounter.id === encounterId;
				})?.encounter;

				if (!updatedEncounter) {
					return old;
				}
				const next = [...old];

				updatedEncounter.currentLocation = room;

				return next;
			});

			// Update selected item
			if (this.selectedEncounterId() === encounterId) {
				this.selectedPatientEncounter.update((old: PatientTracker | null) => {
					if (!old) {
						return old;
					}
					const next = { ...old };
					next.encounter.currentLocation = room;
					return next;
				});
			}
		});

		this.connection.on('EncounterNotesUpdated', (encounterId: string, notes: ReadNotes[]) => {
			// Update loaded item
			this.patientTrackerData.update(old => {
				const updatedEncounter = find(this.patientTrackerData(), (x: PatientTracker) => {
					return x.encounter.id === encounterId;
				})?.encounter;

				// no change if there is no encounter
				if (!updatedEncounter) {
					return old;
				}
				const next = [...old];

				updatedEncounter.notes = notes;

				return next;
			});

			// Update the selected item
			if (this.selectedEncounterId() === encounterId) {
				this.selectedPatientEncounter.update((old: PatientTracker | null) => {
					if (!old) {
						return old;
					}
					const next = { ...old };
					next.encounter.notes = notes;
					return next;
				});
			}
		});

		this.connection.on(
			'EncounterChiefComplaintsUpdated',
			(encounterId: string, chiefComplaints: ChiefComplaintRow[]) => {
				// Update loaded item
				this.patientTrackerData.update(old => {
					const updatedEncounter = find(this.patientTrackerData(), (x: PatientTracker) => {
						return x.encounter.id === encounterId;
					})?.encounter;

					// no change if there is no encounter
					if (!updatedEncounter) {
						return old;
					}
					const next = [...old];

					updatedEncounter.chiefComplaints = chiefComplaints;

					return next;
				});

				// Update the selected item
				if (this.selectedEncounterId() === encounterId) {
					this.selectedPatientEncounter.update((old: PatientTracker | null) => {
						if (!old) {
							return old;
						}
						const next = { ...old };
						next.encounter.chiefComplaints = chiefComplaints;
						return next;
					});
				}
			}
		);
	}

	async setSelectedEncounterById(encounterId: string): Promise<WritableSignal<PatientTracker | null>> {
		if (this.selectedPatientEncounter()?.encounter?.id != encounterId) {
			const patientTrackerData = await this.loadPatientListInfoIfNeeded();

			const patientRow = find(patientTrackerData, (p: PatientTracker) => p.encounter.id === encounterId);
			if (patientRow) {
				this.selectedPatientEncounter.set(patientRow);
				return Promise.resolve(this.selectedPatientEncounter);
			}
			throw new Error(`PatientTrackerEncounter row for "${encounterId}" was not found`);
		}
		return Promise.resolve(this.selectedPatientEncounter);
	}

	private async loadPatientListInfoIfNeeded(): Promise<PatientTracker[]> {
		if (this.patientTrackerDataPromise !== null) {
			return this.patientTrackerDataPromise;
		}

		this.patientTrackerDataPromise = new Promise(resolve => {
			if (!this.patientTrackerDataIsLoaded) {
				const processId = this._appLoaderService.startLoadProcess();
				this._patientListService
					.getAllPatientsDetailed()
					.pipe(
						tap(response => {
							let patientTrackerData: PatientTracker[] = [];
							if (response.ok) {
								const serverData = response.body ?? [];
								if (serverData) {
									this.patientTrackerData.set(serverData);
									patientTrackerData = serverData;
								} else {
									console.error('Dashboard data is null or undefined.');
								}
							}
							this.patientTrackerDataIsLoaded = true;
							resolve(patientTrackerData);
						}),
						finalize(() => {
							this._appLoaderService.completeLoadProcess(processId);
						})
					)
					.subscribe();
			} else {
				resolve(this.patientTrackerData());
			}
		});

		return this.patientTrackerDataPromise;
	}

	getCurrentDashboardSignal(): WritableSignal<PatientTracker[]> {
		this.loadPatientListInfoIfNeeded();
		return this.patientTrackerData;
	}

	testSignalR() {
		this.connection?.send('AddRandomPerson', 1);
	}
}
