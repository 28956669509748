import { Component, forwardRef, OnInit } from '@angular/core';
import { LoadingContainerComponent, NotificationService } from '../../layout';
import {
	BaseIsProcessingComponent,
	PasswordInputFormFieldComponent,
	passwordValidator,
	TextInputFormFieldComponent
} from '../../shared';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { merge, take, takeUntil, tap } from 'rxjs';
import { ChangePasswordRequest } from '../../generated-models/api/auth';
import { AuthService } from '../../auth';

@Component({
	standalone: true,
	selector: 'user-password-settings',
	templateUrl: './user-password-settings.component.html',
	styleUrl: './user-password-settings.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		forwardRef(() => LoadingContainerComponent),
		MatSlideToggleModule,
		TextInputFormFieldComponent,
		PasswordInputFormFieldComponent
	]
})
export class UserPasswordSettingsComponent extends BaseIsProcessingComponent implements OnInit {
	public formGroup: FormGroup = new FormGroup({
		oldPassword: new FormControl<string | null>(null, [Validators.required]),
		newPassword: new FormControl<string | null>(null, [Validators.required, passwordValidator()]),
		verifyNewPassword: new FormControl<string | null>(null, [Validators.required, passwordValidator()])
	});

	get oldPasswordCtrl() {
		return this.formGroup.get('oldPassword') as FormControl;
	}

	get newPasswordCtrl() {
		return this.formGroup.get('newPassword') as FormControl;
	}

	get verifyNewPasswordCtrl() {
		return this.formGroup.get('verifyNewPassword') as FormControl;
	}

	constructor(
		private readonly _authService: AuthService,
		private readonly _notificationService: NotificationService
	) {
		super([_authService.isProcessing$]);

		merge(this.newPasswordCtrl.valueChanges, this.verifyNewPasswordCtrl.valueChanges)
			.pipe(
				takeUntil(this._destroy),
				tap(() => {
					if (this.newPasswordCtrl.value === this.verifyNewPasswordCtrl.value) {
						if (this.newPasswordCtrl?.errors && this.newPasswordCtrl.hasError('passwordsDoNotMatch')) {
							delete this.newPasswordCtrl.errors['passwordsDoNotMatch'];
							this.newPasswordCtrl.updateValueAndValidity({ emitEvent: false });
						}

						if (this.verifyNewPasswordCtrl?.errors && this.verifyNewPasswordCtrl.hasError('passwordsDoNotMatch')) {
							delete this.verifyNewPasswordCtrl.errors['passwordsDoNotMatch'];
							this.verifyNewPasswordCtrl.updateValueAndValidity({ emitEvent: false });
						}
					} else {
						this.newPasswordCtrl.setErrors({ passwordsDoNotMatch: true }, { emitEvent: false });
						this.verifyNewPasswordCtrl.setErrors({ passwordsDoNotMatch: true }, { emitEvent: false });
					}
				})
			)
			.subscribe();
	}

	public ngOnInit(): void {}

	public save(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			const changePasswordRequest: ChangePasswordRequest = {
				oldPassword: this.oldPasswordCtrl.value,
				newPassword: this.newPasswordCtrl.value
			};
			this._authService
				.changePassword(changePasswordRequest)
				.pipe(
					take(1),
					tap(response => {
						if (response.ok) {
							this._notificationService.showSuccessNotification('Your password has been successfully changed.');
							this.formGroup.reset(
								{
									oldPassword: null,
									newPassword: null,
									verifyNewPassword: null
								},
								{ emitEvent: false }
							);
						}
					})
				)
				.subscribe();
		}
	}
}
