import { Component, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ReadNotes, SaveNote } from '../generated-models/api';
import { SiteWideDataService } from '../shared/services/site-wide-data-service.service';
import { find, remove } from 'lodash';
import { AuthService } from '../auth';
import { EncounterService } from '../shared/services/encounter.service';
import { NotificationService } from '../layout';

@Component({
	standalone: true,
	selector: 'notes',
	templateUrl: './notes.component.html',
	styleUrl: './notes.component.scss',
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatSidenavModule,
		MatInputModule,
		MatSelectModule,
		FormsModule,
		ReactiveFormsModule,
		MatCardModule
	]
})
export class CurrentEncounterNotesComponent {
	public notes: SaveNote[] = [];
	public saveButtonEnabled: boolean = false;
	public userFhirId!: string;
	public userDisplayName!: string;

	constructor(
		private _siteWideDataService: SiteWideDataService,
		private _authService: AuthService,
		private _encounterService: EncounterService,
		private _notificationService: NotificationService
	) {
		effect(() => {
			const patientEncounter = this._siteWideDataService.selectedPatientEncounter();
			const serverNotes = patientEncounter?.encounter.notes;
			if (serverNotes) {
				// Remove notes that have been deleted by some other user
				// We would get that notification through signal R.
				const notesToRemove: SaveNote[] = [];
				this.notes.forEach((displayNote: SaveNote) => {
					const noteToRemove = serverNotes.find(note => note.fhirId === displayNote.fhirId);
					if (noteToRemove) {
						notesToRemove.push(noteToRemove);
					}
				});
				remove(this.notes, note => notesToRemove.includes(note));

				// Add or update the notes that remain
				serverNotes.forEach((serverNote: ReadNotes) => {
					const currentNote = find(this.notes, (note: SaveNote) => {
						return note.fhirId == serverNote.fhirId;
					});
					if (currentNote) {
						currentNote.text = serverNote.text;
					} else {
						this.notes.push({ ...serverNote, delete: false });
					}
				});
			}

			if (this.notes.length == 0) {
				this.addNote();
			}
		});

		const userInfo = this._authService.userInfo;
		this.userFhirId = userInfo?.fhirPractitionerId ?? '';
		this.userDisplayName =
			`${userInfo?.title ?? ''} ${userInfo?.firstName ?? ''} ${userInfo?.lastName ?? ''}`.trim();
	}

	public saveNotes(): void {
		const patientEncounter = this._siteWideDataService.selectedPatientEncounter();
		const encounterId = patientEncounter?.encounter.id;
		const patientId = patientEncounter?.patient.id;

		if (encounterId && patientId) {
			// Save notes that have text and no current fhirId (new messages)
			// Or save messages That you are the author of.
			const notesToSave = this.notes.filter(x => (!x.fhirId && x.text) || x.authorFhirId === this.userFhirId);
			this._encounterService.saveEncounterNotes(encounterId, patientId, notesToSave).subscribe(() => {
				this._notificationService.showSuccessNotification('Notes Saved');
				this.saveButtonEnabled = false;
			});
		}
	}

	public addNote(): void {
		this.notes.push({
			text: '',
			delete: false,
			authorFhirId: this.userFhirId,
			authorName: this.userDisplayName
		});
	}

	public removeNote(note: SaveNote): void {
		if (note.fhirId) {
			note.delete = true;
			this.saveButtonEnabled = true;
		} else {
			remove(this.notes, removeMe => removeMe === note);
		}
	}

	public getNoteDisplayLabel(note: SaveNote) {
		if (!note.fhirId) {
			return `${note.authorName} - New Note`;
		}

		return `${note.authorName} - ${note.lastUpdatedDateTimeDisplay}`;
	}

	public onValueChange(note: SaveNote, $event: Event) {
		const startText = note.text;
		const newText = ($event.target as HTMLInputElement).value ?? '';
		note.text = newText;
		// There is probably a bug in here somewhere, but it won't hurt anything.
		// Will just allow for unnecessary saving.
		if (startText != newText) {
			this.saveButtonEnabled = true;
		}
	}
}
