import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';

@Component({
	standalone: true,
	selector: 'list-info-item',
	templateUrl: './list-info-item.component.html',
	styleUrl: './list-info-item.component.scss',
	imports: [CommonModule, MatListModule]
})
export class ListInfoItemComponent {
	@Input()
	public label: string | null | undefined = null;

	@Input()
	public values: string[] | null | undefined = [];

	@Input()
	public noValuesText: string | null = 'N/A';
}
