import { Component, OnInit } from '@angular/core';
import { MatBottomSheetModule, MatBottomSheet } from '@angular/material/bottom-sheet';
import {
	NotificationtToastMessageComponent,
	NotificationtToastMessageComponentData
} from '../notification-toast-message/notification-toast-message.component';
import { NotificationService } from '../services/notification.service';
import { filter, takeUntil, tap } from 'rxjs';
import { Notification } from '../models';
import { BaseComponent } from '../../../shared';

@Component({
	standalone: true,
	selector: 'notification-toast',
	template: '',
	styles: [],
	imports: [MatBottomSheetModule]
})
export class NotificationToastComponent extends BaseComponent implements OnInit {
	constructor(
		private _notificationService: NotificationService,
		private _bottomSheet: MatBottomSheet
	) {
		super();
	}

	public ngOnInit(): void {
		this._notificationService.notificationQueueCount$
			.pipe(
				takeUntil(this._destroy),
				filter((count: number) => count > 0),
				tap(() => {
					const notification: Notification | null = this._notificationService.getNextNotificationFromQueue();

					if (notification) {
						this.showNotificationToast(notification);
					}
				})
			)
			.subscribe();
	}

	private showNotificationToast(notification: Notification): void {
		const notificationToast = this._bottomSheet.open(NotificationtToastMessageComponent, {
			data: {
				notification: notification
			} as NotificationtToastMessageComponentData,
			panelClass: notification?.panelClass
		});

		if (notification.autoClose) {
			setTimeout(() => {
				notificationToast.dismiss();
			}, notification.autoCloseSeconds * 1000);
		}
	}
}
