import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { UserSettingsService } from './user-settings.service';
import { UserSettingsBaseData } from '../generated-models/api/usersettings';

export const settingsResolver: ResolveFn<
	HttpResponse<UserSettingsBaseData>
> = () => {
	return inject(UserSettingsService).getBaseData();
};
