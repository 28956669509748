<navigation-panel-layout [navigationLinks]="navigationLinks">
	<div toolbar-content>
		<span></span>
	</div>
	<div drawer-content>
		<div [@fadeInOutRouteAnimations]="getRouteAnimationData()" style="display: contants; width: 100%">
			<router-outlet />
		</div>
	</div>
</navigation-panel-layout>
