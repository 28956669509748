import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { DefaultPipe } from '../../../shared';

@Component({
	standalone: true,
	selector: 'text-info-item',
	templateUrl: './text-info-item.component.html',
	styleUrl: './text-info-item.component.scss',
	imports: [CommonModule, MatCardModule, DefaultPipe]
})
export class TextInfoItemComponent {
	@Input()
	public label: string | null = null;

	@Input()
	public value: string | null | undefined = null;
}
