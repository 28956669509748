import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { take, takeUntil, tap } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../auth-service.service';
import { BaseIsProcessingComponent } from '../../shared';
import { CommonModule } from '@angular/common';
import { AppLoaderService, NotificationService } from '../../layout';
import { AuthCardComponent } from '../auth-card';
import { ConfirmEmailRequest } from '../../generated-models/api/auth';

@Component({
	standalone: true,
	selector: 'confirm-email',
	templateUrl: './confirm-email.component.html',
	styleUrl: './confirm-email.component.scss',
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,
		AuthCardComponent
	]
})
export class ConfirmEmailComponent extends BaseIsProcessingComponent implements OnInit {
	public formGroup: FormGroup = new FormGroup({
		email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
		confirmationCode: new FormControl<string | null>(null, [Validators.required])
	});

	get emailCtrl() {
		return this.formGroup.get('email') as FormControl;
	}

	get confirmationCodeCtrl() {
		return this.formGroup.get('confirmationCode') as FormControl;
	}

	public isEmailConfirmed: boolean = false;

	constructor(
		public readonly authService: AuthService,
		private readonly _router: Router,
		private readonly _route: ActivatedRoute,
		readonly _appLoaderService: AppLoaderService,
		private readonly _notificationService: NotificationService
	) {
		super([authService.isProcessing$]);

		const processId = 'confirm-email-component-load-process';
		this.isProcessing$
			.pipe(
				takeUntil(this._destroy),
				tap(isProcessing => {
					if (isProcessing) {
						_appLoaderService.startLoadProcess(processId);
					} else {
						_appLoaderService.completeLoadProcess(processId);
					}
				})
			)
			.subscribe();

		const email = this._route.parent?.snapshot.queryParamMap.get('email');

		if (email) {
			this.emailCtrl.setValue(email, { emitEvent: false });
		} else {
			this._router.navigate(['/page-not-found']);
		}

		const confirmationCode = this._route.parent?.snapshot.queryParamMap.get('confirmationCode');

		if (confirmationCode) {
			this.confirmationCodeCtrl.setValue(confirmationCode, { emitEvent: false });
		} else {
			this._router.navigate(['/page-not-found']);
		}
	}

	public ngOnInit(): void {
		const confirmEmailRequest = {
			email: this.emailCtrl.value,
			tenantCode: this.authService.tenantCode!,
			confirmationCode: this.confirmationCodeCtrl.value
		} as ConfirmEmailRequest;

		this.authService
			.confirmEmail(confirmEmailRequest)
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this.isEmailConfirmed = true;
					}
				})
			)
			.subscribe();
	}
}
