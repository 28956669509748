import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'tenant',
	template: '<router-outlet />',
	styles: ['host {display: contents;}'],
	imports: [RouterModule]
})
export class AppTenantComponent {}
