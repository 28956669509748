import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '..';
import { AppLoaderService, NotificationService } from '../../layout';
import { MoveEncounterRoom, SaveEncounterNotes, SaveNote } from '../../generated-models/api';
import { finalize, Observable } from 'rxjs';
import { FacilityRoom } from '../../generated-models/api/admin/facilityroom';

@Injectable({
	providedIn: 'root'
})
export class EncounterService extends BaseService<void> {
	constructor(
		_httpClient: HttpClient,
		_notificationService: NotificationService,
		private _appLoaderService: AppLoaderService
	) {
		super('encounter', _httpClient, _notificationService);
	}

	public moveRoom(encounterId: string, room: FacilityRoom): Observable<HttpResponse<void>> {
		const processId = this._appLoaderService.startLoadProcess();
		const model: MoveEncounterRoom = {
			encounterId: encounterId,
			roomFhirId: room.fhirLocationId,
			roomFhirDisplay: room.label ?? ''
		};
		return this.post<MoveEncounterRoom, void>(`move-room`, 'Failed to move rooms.', model).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public saveEncounterNotes(
		encounterId: string,
		patientId: string,
		notes: SaveNote[]
	): Observable<HttpResponse<void>> {
		const processId = this._appLoaderService.startLoadProcess();
		const model: SaveEncounterNotes = {
			encounterId: encounterId,
			patientId: patientId,
			notes: notes
		};
		return this.post<SaveEncounterNotes, void>(`save-notes`, 'Failed to save encounter notes.', model).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}
}
