import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		//const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{6,}$/;
		const passwordPattern = /^(.*)$/;
		if (!control.value || passwordPattern.test(control.value)) {
			return null;
		} else {
			return { invalidPassword: true };
		}
	};
}
