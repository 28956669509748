<fieldset>
	<legend>{{ label }}</legend>

	@if (!values || values.length === 0) {
		{{ noValuesText }}
	} @else {
		<mat-list>
			@for (value of values; track value) {
				<mat-list-item>
					<span>{{ value }}</span>
				</mat-list-item>
			}
		</mat-list>
	}
</fieldset>
