<form [formGroup]="formGroup">
	<loading-container [isProcessing]="isProcessing$ | async">
		<select-input-form-field
			label="Default Facility"
			[formCtrl]="defaultUserFacilityIdCtrl"
			[options]="userDetailSettings?.userFacilities ?? []"
			fullWidth></select-input-form-field>

		<text-info-item label="First Name" [value]="userDetailSettings?.firstName | default"></text-info-item>
		<text-info-item label="Middle Name" [value]="userDetailSettings?.middleName | default"></text-info-item>
		<text-info-item label="Last Name" [value]="userDetailSettings?.lastName | default"></text-info-item>
		<text-info-item label="Title" [value]="userDetailSettings?.title | default"></text-info-item>
		<text-info-item label="Email" [value]="userDetailSettings?.email | default"></text-info-item>
		<text-info-item
			label="Phone Number"
			[value]="
				userDetailSettings?.phoneNumber | phone: userDetailSettings?.phoneCountryCode | default
			"></text-info-item>
		<text-info-item label="Roles" [value]="userDetailSettings?.roles ?? [] | split | default"></text-info-item>
		<text-info-item label="Facilities" [value]="userFacilityNames | split | default"></text-info-item>
		<text-info-item
			label="Required MFA Types"
			[value]="userDetailSettings?.requiredMFATypes ?? [] | split | default"></text-info-item>

		<ng-container container-action-content>
			<button type="submit" mat-flat-button (click)="save()" [disabled]="isProcessing$ | async">
				<mat-icon aria-hidden="false">save</mat-icon>Save
			</button>
		</ng-container>
	</loading-container>
</form>
