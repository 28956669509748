import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'mask',
	standalone: true
})
export class MaskPipe implements PipeTransform {
	transform(
		value: string | null | undefined,
		leaveLastNCharactersUnmasked: number | null = null,
		excludeCharacterFromMasking: string | null = null
	): string | null | undefined {
		const n: number = leaveLastNCharactersUnmasked
			? leaveLastNCharactersUnmasked
			: value?.length
				? value.length
				: 0;

		if (!value || n <= 0) {
			return value;
		}

		const length = value?.length;
		if (length <= n) {
			return value;
		}

		let regex = new RegExp('.', 'g');

		if (excludeCharacterFromMasking) {
			regex = new RegExp(`[^${excludeCharacterFromMasking}]`, 'g');
		}

		const maskedPart = value.slice(0, length - n).replace(regex, '●');
		const visiblePart = value.slice(length - n);

		return maskedPart + visiblePart;
	}
}
