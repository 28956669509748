import { Component, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
	standalone: true,
	selector: 'text-input-form-field',
	templateUrl: './text-input-form-field.component.html',
	styleUrl: './text-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule]
})
export class TextInputFormFieldComponent extends BaseInputFormFieldComponent {
	private _autocomplete: string = 'on';

	public maxLength = input<number>();

	public readonly = input<boolean>(false);

	@Input()
	public placeHolder: string | null | undefined = null;

	@Input()
	public inputType: string = 'text';

	@Input()
	set autoCompleteOff(value: BooleanInput) {
		if (coerceBooleanProperty(value)) {
			this._autocomplete = 'off';
		}
	}

	get autoComplete(): string {
		return this._autocomplete;
	}

	constructor() {
		super('The value');
	}
}
