import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { UserDetailSettingsService } from './user-detail-settings.service';
import { UserDetailSettingsBaseData } from '../../generated-models/api/usersettings/detail';

export const userDetailSettingsResolver: ResolveFn<
	HttpResponse<UserDetailSettingsBaseData>
> = () => {
	return inject(UserDetailSettingsService).getBaseData();
};
