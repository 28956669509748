import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TimeTrailingZeroPipe } from '../../pipes';

@Component({
	standalone: true,
	selector: 'elapsed-time',
	templateUrl: './elapsed-time.component.html',
	styleUrls: ['./elapsed-time.component.scss'],
	imports: [TimeTrailingZeroPipe]
})
export class ElapsedTimeComponent implements OnInit, OnDestroy {
	@Input({ required: true }) startTimeUtc!: Date | string;

	public elapsedHours: number = 0;
	public elapsedMinutes: number = 0;
	public elapsedSeconds: number = 0;

	private _timerSubscription: Subscription | null = null;

	constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

	public ngOnInit(): void {
		this.startTimer();
	}

	public ngOnDestroy(): void {
		if (this._timerSubscription) {
			this._timerSubscription.unsubscribe();
		}
	}

	private startTimer(): void {
		this._timerSubscription = interval(1000).subscribe(() => {
			const now = new Date();

			let startTimeUtc: Date;

			if (typeof this.startTimeUtc === 'string') {
				startTimeUtc = new Date(this.startTimeUtc);
			} else {
				startTimeUtc = this.startTimeUtc;
			}

			const elapsed = Math.floor((now.getTime() - startTimeUtc.getTime()) / 1000);

			this.elapsedHours = Math.floor(elapsed / 3600);
			this.elapsedMinutes = Math.floor((elapsed % 3600) / 60);
			this.elapsedSeconds = elapsed % 60;

			this._changeDetectorRef.detectChanges();
		});
	}
}
