import { ChangeDetectionStrategy, Component, input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'boolean-text-select-input-form-field',
	templateUrl: './boolean-text-select-input-form-field.component.html',
	styleUrl: './boolean-text-select-input-form-field.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BaseInputFormFieldComponent,
		MatFormFieldModule,
		MatButtonModule
	]
})
export class BooleanTextSelectInputFormFieldComponent
	extends BaseInputFormFieldComponent
	implements OnInit, OnDestroy
{
	constructor() {
		super('A selection');
	}

	private _destroy: Subject<void> = new Subject();

	private _state: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

	public $state = this._state.asObservable();

	public readonly = input<boolean>(false);

	public toggleState(): void {
		if (!this.readonly()) {
			this._state.next(!this._state.getValue());
		}
	}

	public ngOnInit(): void {
		const value = this.formCtrl.value;

		this._state.next(value);

		this.$state
			.pipe(
				takeUntil(this._destroy),
				tap(value => {
					this.formCtrl.setValue(value);
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this._state.complete();
		this._destroy.complete();
	}
}
