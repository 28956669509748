@if (showFormField) {
	<div class="form-field-wrapper">
		<mat-form-field [ngClass]="{ 'full-width': fullWidth, short: short }">
			@if (label) {
				<mat-label style="white-space: nowrap">{{ label }}</mat-label>
			}
			@if (focusInitial) {
				<input
					#inputRef
					matInput
					type="text"
					[placeholder]="placeHolder ? placeHolder : ''"
					[formControl]="internalControl"
					maxLength="7"
					cdkFocusInitial
					[readonly]="readonly()"
					weight />
			} @else {
				<input
					#inputRef
					matInput
					type="text"
					[placeholder]="placeHolder ? placeHolder : ''"
					[formControl]="internalControl"
					[readonly]="readonly()"
					maxLength="7"
					weight />
			}
			<span class="suffix" matSuffix
				>{{ weightType }}
				(
				@if (convertedValueDisplay) {
					{{ convertedValueDisplay }}
				} @else {
					<span class="spacer">&nbsp;</span>
				}
				{{ otherWeightType }})
			</span>

			@if (formCtrl && formCtrl.hasError('required')) {
				<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
			}

			@if (formCtrl && formCtrl.hasError('temperatureInvalidRange')) {
				<mat-error>{{ errorLabelToLowerExceptFirst }} is an invalid range.</mat-error>
			}
		</mat-form-field>

		<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
			@if (formCtrl && formCtrl.hasError(customError.type)) {
				<mat-error>{{ customError.message }}</mat-error>
			}
		</ng-template>
	</div>
}
