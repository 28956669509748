<mat-toolbar>
	<a class="logo-link" [routerLink]="'/' + authService.tenantCode" aria-label="Open InSyts EMR website">
		<!-- <img class="logo" alt="logo" src="./assets/logo.png" /> -->
		InSyts EMR
	</a>

	<div class="bread-crumbs-container">
		@if (((breadcrumbs$ | async) ?? []).length > 0) {
			<mat-icon aria-hidden="false">arrow_right</mat-icon>
		}

		<ng-container *ngFor="let breadCrumb of breadcrumbs$ | async; index as index; count as count">
			<!-- <a mat-flat-button [routerLink]="breadCrumb.url">{{ breadCrumb.label }}</a> -->

			<div class="breadcrumb">
				<span>{{ breadCrumb.label }}</span>
			</div>

			<ng-container *ngIf="index < count - 1">
				<mat-icon aria-hidden="false">arrow_right</mat-icon>
			</ng-container>
		</ng-container>
	</div>
	<span class="spacer"></span>

	<div class="user-info">
		<span class="user-name">{{ userName | default }}</span>
		<span class="user-facility">{{ userInfo?.facility?.label | default }}</span>
	</div>

	<button mat-icon-button aria-label="User Information" [matMenuTriggerFor]="userInformationMenu">
		<mat-icon aria-hidden="false">account_circle</mat-icon>
	</button>

	<mat-menu #userInformationMenu="matMenu" xPosition="before" yPosition="below">
		<a mat-menu-item [routerLink]="'/' + authService.tenantCode + '/user-settings'">
			<mat-icon aria-hidden="false">manage_accounts</mat-icon>
			<span>User Settings</span>
		</a>

		<a mat-menu-item [routerLink]="'/' + authService.tenantCode + '/admin'" *hasClaim="['IsAdmin']">
			<mat-icon aria-hidden="false">admin_panel_settings</mat-icon>
			<span>Admin</span>
		</a>

		<a
			mat-menu-item
			[routerLink]="'/' + authService.tenantCode + '/sandbox'"
			*hasClaim="['CanViewUnderDevelopment']">
			<mat-icon aria-hidden="false">castle</mat-icon>
			<span>Sandbox</span>
		</a>

		<button mat-menu-item (click)="changeFacility()" *hasClaim="['CanChangeFacility']">
			<mat-icon aria-hidden="false">domain</mat-icon>
			<span>Change Facility</span>
		</button>

		<a mat-menu-item [routerLink]="'/' + authService.tenantCode + '/support'">
			<mat-icon aria-hidden="false">support</mat-icon>
			<span>Support</span>
		</a>

		<button mat-menu-item (click)="logout()">
			<mat-icon aria-hidden="false">logout</mat-icon>
			<span>Logout</span>
		</button>
	</mat-menu>
</mat-toolbar>
