/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MFAOption {
    None = 0,
    PhoneCall = 1,
    SMS = 2,
    TOTP = 3,
}

export const MFAOptionDisplayMap : { [key in MFAOption] : string } = {
    [MFAOption.None]: "None",
    [MFAOption.PhoneCall]: "PhoneCall",
    [MFAOption.SMS]: "SMS",
    [MFAOption.TOTP]: "TOTP",
}
