import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../../shared';
import { NotificationService } from '../../layout';
import {
	EditUserDetailSettingsResponse,
	UserDetailSettings,
	UserDetailSettingsBaseData
} from '../../generated-models/api/usersettings/detail';

@Injectable({
	providedIn: 'root'
})
export class UserDetailSettingsService extends BaseService<UserDetailSettingsBaseData> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('user-settings', _httpClient, _notificationService);
	}

	public loadUserDetails(): Observable<HttpResponse<UserDetailSettings>> {
		return this.get<UserDetailSettings>('detail', 'Failed to retrieve the user details.');
	}

	public editUserDetails(response: EditUserDetailSettingsResponse): Observable<HttpResponse<void>> {
		return this.post<EditUserDetailSettingsResponse, void>(
			'detail',
			'Failed to edit the user details.',
			response
		);
	}
}
