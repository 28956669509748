import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { distinctUntilChanged, filter, map, startWith, take, takeUntil, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Breadcrumb } from './models/breadcrumb.model';
import { MatBadgeModule } from '@angular/material/badge';
import { BaseComponent, HasClaimDirective, SecurityService } from '../../shared';
import { AppSidenavConfig, AppSidenavService } from '../app-sidenav';
import { AuthService } from '../../auth';
import { DefaultPipe } from '../../shared/pipes/default.pipe';
import { CurrentEncounterNotesComponent } from '../../notes/notes.component';
import { ChangeFacilityComponent } from '../change-facility/change-facility.component';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, UrlSegment } from '@angular/router';
import { UserInfo } from '../../generated-models/api/auth';
import { FhirService } from '../../shared/services/fhir-service.service';
@Component({
	standalone: true,
	selector: 'app-nav-bar',
	templateUrl: './app-nav-bar.component.html',
	styleUrl: './app-nav-bar.component.scss',
	imports: [
		CommonModule,
		MatToolbarModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatBadgeModule,
		ChangeFacilityComponent,
		CurrentEncounterNotesComponent,
		DefaultPipe,
		HasClaimDirective,
		RouterLink
	],
	providers: [SecurityService]
})
export class AppNavBarComponent extends BaseComponent implements OnInit, OnDestroy {
	@Output() menuClick: EventEmitter<void> = new EventEmitter();

	public isAdmin: boolean = true;
	public hasSandboxAccess: boolean = true;

	public breadcrumbs$ = this._router.events.pipe(
		takeUntil(this._destroy),
		filter(event => event instanceof NavigationEnd),
		distinctUntilChanged(),
		map(() => {
			return this.buildBreadCrumb(this._activatedRoute.root);
		})
	);

	public userInfo: UserInfo | null = null;
	public userName: string | null = null;

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _appSidenavService: AppSidenavService,
		public authService: AuthService,
		private _securityService: SecurityService,
		public fhirService: FhirService
	) {
		super();
	}

	public ngOnInit(): void {
		this.authService.$userInfo
			.pipe(
				startWith(this.authService.userInfo),
				takeUntil(this._destroy),
				tap(userInfo => {
					this.userInfo = userInfo;
					this._securityService.claims = this.userInfo?.claims ?? [];
					this.userName = this.buildName();
				})
			)
			.subscribe();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	// private GetRootTemplateFromAction(action: string): string {
	// 	let rootTemplate = '';
	// 	switch (action) {
	// 		case 'AdmitPatient':
	// 			rootTemplate = 'ADT_A01';
	// 			break;
	// 		case 'TransferPatient':
	// 			rootTemplate = 'ADT_A02';
	// 			break;
	// 		case 'DischargePatient':
	// 			rootTemplate = 'ADT_A03';
	// 			break;
	// 		case 'RegisterPatient':
	// 			rootTemplate = 'ADT_A04';
	// 			break;
	// 		case 'UpdatePatient':
	// 			rootTemplate = 'ADT_A08';
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// 	return rootTemplate;
	// }

	onCreateNewPatient(): void {
		//this.fhirService.createPatientFromMessage(convertRequest).subscribe(resp => console.log(resp));
	}

	public changeFacility() {
		const sidenavData = null;

		const appSidenavConfig: AppSidenavConfig = {
			title: 'Change Facility',
			onCloseCallback: () => {}
		};

		this._appSidenavService.showSidenav(ChangeFacilityComponent, sidenavData, appSidenavConfig);
	}

	public logout(): void {
		this.authService.logout().pipe(take(1)).subscribe();
	}

	private buildBreadCrumb(routeRoot: ActivatedRoute): Breadcrumb[] {
		const newBreadcrumbs: Breadcrumb[] = [];
		let url: string = '';

		let currentRoute: ActivatedRoute | null = routeRoot;

		do {
			const childrenRoutes = currentRoute.children;
			currentRoute = null;
			childrenRoutes.forEach((route: ActivatedRoute) => {
				if (route.outlet === 'primary') {
					const routeSnapshot = route.snapshot;

					const label: string = route.routeConfig?.data ? route.routeConfig.data['breadcrumb'] : null;

					url += '/' + routeSnapshot.url.map((segment: UrlSegment) => segment.path).join('/');

					if (label && url) {
						newBreadcrumbs.push({
							label: label,
							url: url
						} as Breadcrumb);
					}

					currentRoute = route;
				}
			});
		} while (currentRoute);

		return newBreadcrumbs;
	}

	private buildName(): string {
		return `${this.userInfo?.title ? `${this.userInfo.title} ` : ''}${this.userInfo?.firstName} ${this.userInfo?.lastName}`;
	}
}
