import { Component, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatSelectModule } from '@angular/material/select';
import { IdLabel, KeyLabel } from '../../../../generated-models/api/shared';
import { BaseInputFormFieldComponent } from '../base-input-form-field';

@Component({
	standalone: true,
	selector: 'select-input-form-field',
	templateUrl: './select-input-form-field.component.html',
	styleUrl: './select-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, BaseInputFormFieldComponent, MatSelectModule]
})
export class SelectInputFormFieldComponent extends BaseInputFormFieldComponent {
	private _idLabelOptions: IdLabel[] = [];
	private _keyLabelOptions: KeyLabel[] = [];
	private _multiple: boolean = false;

	public isIdLabelOptions = false;
	public isKeyLabelOptions = false;

	public readonly = input<boolean>(false);

	@Input()
	set options(value: IdLabel[]) {
		this.isIdLabelOptions = true;
		this._idLabelOptions = value;
	}

	@Input()
	set keyLabelOptions(value: KeyLabel[]) {
		this.isKeyLabelOptions = true;
		this._keyLabelOptions = value;
	}

	get idLabelOptions() {
		return this._idLabelOptions;
	}

	get keyLabelOptions() {
		return this._keyLabelOptions;
	}

	@Input()
	set multiple(value: BooleanInput) {
		this._multiple = coerceBooleanProperty(value);
	}

	get multiple(): boolean {
		return this._multiple;
	}

	constructor() {
		super('A selection');
	}
}
