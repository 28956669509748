import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	forwardRef,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	Observable,
	of,
	startWith,
	switchMap,
	takeUntil,
	tap
} from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { BaseComponent } from '../base-component.component';

export interface SelectTypeaheadOption {
	key: string | null;
	label: string;
	isFavorite?: boolean | null | undefined;
}

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'select-typeahead',
	templateUrl: './select-typeahead.component.html',
	styleUrl: './select-typeahead.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatIconModule,
		MatAutocompleteModule
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectTypeaheadComponent),
			multi: true
		}
	]
})
export class SelectTypeaheadComponent
	extends BaseComponent
	implements ControlValueAccessor, OnInit, AfterViewInit
{
	@ViewChild('searchInput') searchInput!: ElementRef;

	private _fullWidth: boolean = false;

	protected onTouched!: () => void;
	protected onChange!: (value: SelectTypeaheadOption | null | undefined) => void;

	public readonly formGroup: FormGroup = new FormGroup({
		searchInput: new FormControl<string | null>(null)
	});

	get searchInputCtrl(): FormControl {
		return this.formGroup.get('searchInput') as FormControl;
	}

	@Input()
	public placeHolder: string | null | undefined = null;

	@Input()
	public label: string | null | undefined = null;

	@Input({ required: true })
	public optionsFilterCallBack: ((value: string) => Observable<SelectTypeaheadOption[]>) | null = null;

	@Input()
	public loadFavoriteOptionsCallBack: (() => Observable<SelectTypeaheadOption[]>) | null = null;

	@Input()
	public minTypeaheadLength: number = 0;

	@Input()
	set fullWidth(value: BooleanInput) {
		this._fullWidth = coerceBooleanProperty(value);
	}

	get fullWidth(): boolean {
		return this._fullWidth;
	}

	public favoriteOptions: SelectTypeaheadOption[] = [];
	public filteredOptions: SelectTypeaheadOption[] = [];

	constructor() {
		super();
	}

	writeValue(obj: SelectTypeaheadOption | null | undefined): void {
		if (!obj) {
			this.searchInputCtrl.reset('', { emitEvent: true });
		}
	}

	registerOnChange(fn: (values: SelectTypeaheadOption | null | undefined) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.formGroup.disable({ emitEvent: false });
		} else {
			this.formGroup.enable({ emitEvent: false });
		}
	}

	public ngOnInit(): void {
		this.searchInputCtrl.valueChanges
			.pipe(
				takeUntil(this._destroy),
				startWith(''),
				filter(f => f === '' || f === null),
				switchMap(val => {
					return this.loadFavorites();
				}),
				tap(value => {
					this.filteredOptions = [];
					this.favoriteOptions = value;
				})
			)
			.subscribe();

		this.searchInputCtrl.valueChanges
			.pipe(
				takeUntil(this._destroy),
				startWith(''),
				filter(f => typeof f === 'string'),
				filter(f => (f?.length ?? 0) >= this.minTypeaheadLength),
				debounceTime(300),
				switchMap(val => {
					return this.filter(val || '');
				}),
				tap(value => {
					this.favoriteOptions = [];
					this.filteredOptions = value;
				})
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		this.searchInputCtrl.valueChanges
			.pipe(
				takeUntil(this._destroy),
				tap(value => {
					if (!value || value === '') {
						this.onChange(null);
					}
				})
			)
			.subscribe();
	}

	public selected(event: MatAutocompleteSelectedEvent): void {
		const selectedOption: SelectTypeaheadOption = event.option.value;
		this.onChange(selectedOption);
		this.searchInputCtrl.reset(selectedOption.label, { emitEvent: false });
	}

	private filter(value: string): Observable<SelectTypeaheadOption[]> {
		if (this.optionsFilterCallBack) {
			return this.optionsFilterCallBack(value);
		} else {
			return of([]);
		}
	}

	private loadFavorites(): Observable<SelectTypeaheadOption[]> {
		if (this.loadFavoriteOptionsCallBack) {
			return this.loadFavoriteOptionsCallBack();
		} else {
			return of([]);
		}
	}
}
