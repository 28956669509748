import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { UserPasswordSettingsService } from './user-password-settings.service';
import { UserPasswordSettingsBaseData } from '../../generated-models/api/usersettings/password';

export const userPasswordSettingsResolver: ResolveFn<
	HttpResponse<UserPasswordSettingsBaseData>
> = () => {
	return inject(UserPasswordSettingsService).getBaseData();
};
