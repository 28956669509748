import { Component, forwardRef, OnInit } from '@angular/core';
import { AdminContainerComponent, NotificationService } from '../../layout';
import { BaseIsProcessingComponent, SelectInputFormFieldComponent } from '../../shared';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { take, tap } from 'rxjs';
import { UserDetailSettingsService } from './user-detail-settings.service';
import {
	EditUserDetailSettingsResponse,
	UserDetailSettings
} from '../../generated-models/api/usersettings/detail';
import { InfoItemSectionComponent } from '../../layout/info-item-section/info-item-section.component';
import { TextInfoItemComponent } from '../../layout/info-items/text-info-item/text-info-item.component';
import { LoadingContainerComponent } from '../../layout/loading-container/loading-container.component';
import { DefaultPipe } from '../../shared/pipes/default.pipe';
import { SplitPipe } from '../../shared/pipes/split-pipe';
import { PhonePipe } from '../../shared/pipes/phone.pipe';

@Component({
	standalone: true,
	selector: 'user-detail-settings',
	templateUrl: './user-detail-settings.component.html',
	styleUrl: './user-detail-settings.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		forwardRef(() => AdminContainerComponent),
		MatSlideToggleModule,
		SelectInputFormFieldComponent,
		InfoItemSectionComponent,
		TextInfoItemComponent,
		LoadingContainerComponent,
		DefaultPipe,
		SplitPipe,
		PhonePipe
	]
})
export class UserDetailSettingsComponent extends BaseIsProcessingComponent implements OnInit {
	public userDetailSettings: UserDetailSettings | null = null;

	public formGroup: FormGroup = new FormGroup({
		defaultUserFacilityId: new FormControl<number[] | null>(null, Validators.required)
	});

	get defaultUserFacilityIdCtrl() {
		return this.formGroup.get('defaultUserFacilityId') as FormControl;
	}

	get userFacilityNames(): string[] {
		const userFacilities = this.userDetailSettings?.userFacilities;

		const userFacilityNames: string[] = [];

		if (userFacilities) {
			userFacilities.forEach(value => {
				if (value.label) {
					userFacilityNames.push(value.label);
				}
			});
		}

		return userFacilityNames;
	}

	constructor(
		private readonly _userDetailSettingsService: UserDetailSettingsService,
		private readonly _notificationService: NotificationService
	) {
		super([]);
	}

	public ngOnInit(): void {
		this.load();
	}

	public load(): void {
		this._userDetailSettingsService
			.loadUserDetails()
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this.userDetailSettings = response.body;

						this.formGroup.patchValue(
							{
								defaultUserFacilityId: response.body?.defaultUserFacilityId
							},
							{ emitEvent: false }
						);
					}
				})
			)
			.subscribe();
	}

	public save(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			const editUserDetailSettingsResponse: EditUserDetailSettingsResponse = {
				defaultUserFacilityId: this.defaultUserFacilityIdCtrl.value
			};
			this._userDetailSettingsService
				.editUserDetails(editUserDetailSettingsResponse)
				.pipe(
					take(1),
					tap(response => {
						if (response.ok) {
							this._notificationService.showSuccessNotification('Changes saved.');
							this.load();
						}
					})
				)
				.subscribe();
		}
	}
}
