export function phoneFormatter(
	phoneNumber: string | null | undefined,
	countryCode: number | null | undefined = null
): string {
	const fullPhoneNumber = (countryCode ? countryCode : '') + (phoneNumber ?? '');

	if (countryCode) {
		const countryCodeStr = fullPhoneNumber.slice(0, 1);
		const areaCodeStr = fullPhoneNumber.slice(1, 4);
		const midSectionStr = fullPhoneNumber.slice(4, 7);
		const lastSectionStr = fullPhoneNumber.slice(7);

		return `+${countryCodeStr} (${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
	} else {
		const areaCodeStr = fullPhoneNumber.slice(0, 3);
		const midSectionStr = fullPhoneNumber.slice(3, 6);
		const lastSectionStr = fullPhoneNumber.slice(6);

		return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
	}
}
