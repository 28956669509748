import { Component, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
	standalone: true,
	selector: 'date-time-input-form-field',
	templateUrl: './date-time-input-form-field.component.html',
	styleUrl: './date-time-input-form-field.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MtxDatetimepickerModule
	]
})
export class DateTimeInputFormFieldComponent extends BaseInputFormFieldComponent {
	public touchUi: boolean = false;

	public readonly = input<boolean>(false);

	@Input()
	public hint: string | null | undefined = null;

	@Input()
	set useTouchUi(value: BooleanInput) {
		this.touchUi = coerceBooleanProperty(value);
	}

	constructor() {
		super('The datetime');
	}
}
