import { Component, computed, Inject, Input } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { FacilityRoom } from '../../../../generated-models/api/admin/facilityroom';
import { SiteWideDataService } from '../../../services/site-wide-data-service.service';
import { PatientTracker } from '../../../../generated-models/api';
import { EncounterService } from '../../../services/encounter.service';
import { NotificationService } from '../../../../layout';
import { MatIconModule } from '@angular/material/icon';

interface RoomListHelper {
	room: FacilityRoom;
	id: number;
	enabled: boolean;
	icon?: string | null;
	label?: string | null;
}

export interface ChangeRoomBottomSheetComponentData {
	rooms: FacilityRoom[];
	row: PatientTracker;
}

@Component({
	selector: 'change-room-bottom-sheet',
	templateUrl: 'change-room-bottom-sheet.html',
	standalone: true,
	imports: [MatListModule, MatIconModule]
})
export class ChangeRoomBottomSheetComponent {
	@Input()
	public data: ChangeRoomBottomSheetComponentData | null = null;

	public roomListHelper = computed<RoomListHelper[]>(() => {
		const dashboardSignal = this._siteWideDataService.getCurrentDashboardSignal();
		const currentEncounters = dashboardSignal();
		const inWaitingRoom = this.data?.row.encounter.currentLocation?.isWaitingRoom;
		const inUseRoomIds: string[] = currentEncounters.map(
			row => row.encounter?.currentLocation?.locationId ?? ''
		);

		return (
			this.data?.rooms?.map(room => {
				const isWaitingRoomOption = room.roomType.label?.toLocaleLowerCase().includes('waiting');
				return {
					room: room,
					id: room.id,
					label: room.label,
					icon: room.roomType.matIcon,
					enabled: !inUseRoomIds.includes(room.fhirLocationId) || (!!isWaitingRoomOption && !inWaitingRoom)
				};
			}) ?? []
		);
	});

	constructor(
		@Inject(MAT_BOTTOM_SHEET_DATA) _data: ChangeRoomBottomSheetComponentData,
		private _bottomSheetRef: MatBottomSheetRef<ChangeRoomBottomSheetComponent>,
		private _siteWideDataService: SiteWideDataService,
		private _encounterService: EncounterService,
		private _notificationService: NotificationService
	) {
		//super();

		if (_data) {
			this.data = _data;
		}
	}

	public confirm(): void {
		// if (this.data?.confirmationCallback) {
		// 	this.data.confirmationCallback();
		// }

		this._bottomSheetRef.dismiss();
	}

	public close(): void {
		// if (this.data?.cancelCallback) {
		// 	this.data.cancelCallback();
		// }

		this._bottomSheetRef.dismiss();
	}

	public changePatientRoom(room: FacilityRoom): void {
		const row = this.data?.row;

		this._encounterService.moveRoom(row?.encounter.id ?? '', room).subscribe(() => {
			const patientName = row?.patient.firstName + ' ' + row?.patient.lastName;
			this._notificationService.showSuccessNotification(`${patientName} has been moved to ${room.label}`);
		});
	}
}
