import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../shared/services/base-service.service';
import { AppLoaderService, NotificationService } from '../layout';
import { PatientTrackerBaseData } from '../generated-models/api/patienttracker';
import { PatientTracker } from '../generated-models/api';

@Injectable({
	providedIn: 'root'
})
export class PatientListService extends BaseService<PatientTrackerBaseData> {
	constructor(
		_httpClient: HttpClient,
		_notificationService: NotificationService,
		private _appLoaderSeriver: AppLoaderService
	) {
		super('patient-list', _httpClient, _notificationService);
	}

	public getAllPatientsDetailed(): Observable<HttpResponse<PatientTracker[]>> {
		return this.get<PatientTracker[]>('patients', 'Failed to retrieve the dashboard data');
	}
}
