import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService, handleResponse, HandleResponseConfig } from '../../shared';
import { NotificationService } from '../../layout';
import { UserMFASettingsBaseData } from '../../generated-models/api/usersettings/mfa';
import { TOTPSecret } from '../../generated-models/api/usersettings/mfa/t-o-t-p-secret.model';
import { UserMFASettings } from '../../generated-models/api/usersettings/mfa/user-m-f-a-settings.model';
import { ValidateSetupPhoneNumberFMARequest } from '../../generated-models/api/usersettings/mfa/validate-setup-phone-number-f-m-a-request.model';
import { ValidateSetupTOTPMFARequest } from '../../generated-models/api/usersettings/mfa/validate-setup-t-o-t-p-m-f-a-request.model';

@Injectable({
	providedIn: 'root'
})
export class UserMFASettingsService extends BaseService<UserMFASettingsBaseData> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('user-settings/mfa', _httpClient, _notificationService);
	}

	public setupPhoneNumberMFA(isCall: boolean = false): Observable<HttpResponse<void>> {
		return this._httpClient
			.get<void>(`${this.apiEndpoint}/setup-phone-number-mfa${isCall ? '?iscall=true' : ''}`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}

	public loadUserMFASettings(): Observable<HttpResponse<UserMFASettings>> {
		return this._httpClient
			.get<UserMFASettings>(`${this.apiEndpoint}`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}

	public validateSetupPhoneNumberMFA(request: ValidateSetupPhoneNumberFMARequest): Observable<HttpResponse<void>> {
		return this._httpClient
			.post<void>(`${this.apiEndpoint}/validate-setup-phone-number-mfa`, request, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}

	public setupTOTPMFA(): Observable<HttpResponse<TOTPSecret>> {
		return this._httpClient
			.get<TOTPSecret>(`${this.apiEndpoint}/setup-totp-mfa`, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}

	public validateSetupTOTPMFA(request: ValidateSetupTOTPMFARequest): Observable<HttpResponse<void>> {
		return this._httpClient
			.post<void>(`${this.apiEndpoint}/validate-setup-totp-mfa`, request, {
				observe: 'response'
			})
			.pipe(
				handleResponse(this.processResponse, this._notificationService, {
					defaultErrorMessage: 'Failed.'
				} as HandleResponseConfig)
			);
	}
}
