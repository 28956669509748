import { Injectable } from '@angular/core';

@Injectable()
export class SecurityService {
	private _claims: string[] = [];

	set claims(claims: string[]) {
		this._claims = claims;
	}

	public hasClaim(claimType: string | string[]) {
		let ret: boolean = false;

		// See if an array of values was passed in.
		if (typeof claimType === 'string') {
			ret = this.isClaimValid(claimType);
		} else {
			const claims: string[] = claimType;
			if (claims) {
				for (let index = 0; index < claims.length; index++) {
					ret = this.isClaimValid(claims[index]);
					// If one is successful, then let them in
					if (ret) {
						break;
					}
				}
			}
		}

		return ret;
	}

	private isClaimValid(claim: string): boolean {
		const isValid: boolean = this._claims.indexOf(claim) > -1;
		return isValid;
	}
}
