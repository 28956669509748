import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../layout';
import { BaseService } from '../shared';
import { UserSettingsBaseData } from '../generated-models/api/usersettings';

@Injectable({
	providedIn: 'root'
})
export class UserSettingsService extends BaseService<UserSettingsBaseData> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('user-settings', _httpClient, _notificationService);
	}
}
